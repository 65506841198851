import { ModuleModelProvider } from '@beeldit/crud-generator/module-models/contexts/ModuleModelContext';
import ModuleModelPage from '@beeldit/crud-generator/module-models/pages/ModuleModelPage';
import { ModuleProvider } from '@beeldit/crud-generator/modules/contexts/ModuleContext';
import ModulePage from '@beeldit/crud-generator/modules/pages/ModulePage';
import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LandingProvider } from '@main/modules/landings/contexts/LandingContext';
import LandingPage from '@main/modules/landings/pages/LandingPage';
import { LayoutProvider } from '@main/modules/layouts/contexts/LayoutContext';
import LayoutPage from '@main/modules/layouts/pages/LayoutPage';
import { SeoMetadataProvider } from '@beeldit/seo/seo-metadatas/contexts/SeoMetadataContext';
import SeoMetadataPage from '@beeldit/seo/seo-metadatas/pages/SeoMetadataPage';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppNavbar from './AppNavbar';
import Sidebar from './Sidebar';
import PublicLandingPage from '@main/modules/landings/pages/PublicLandingPage';

function Panel() {
  const { tokenLoaded, user} = useContext(AuthContext);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items: any[] = [
    { label: 'Modules', to: '/panel/modules', icon: icon({name: 'layer-group', style: 'solid'}) },
    { label: 'Models', to: '/panel/module-models', icon: icon({name: 'puzzle-piece', style: 'solid'}) },
    // { label: 'Forms', to: '/panel/forms', icon: icon({name: 'bars', style: 'solid'}) },
    // { label: 'Tables', to: '/panel/tables', icon: icon({name: 'database', style: 'solid'}) },
    // { label: 'Listing', to: '/panel/listing', icon: icon({name: 'table-list', style: 'solid'}) },
    { label: 'Layouts', to: '/panel/layouts', icon: icon({name: 'layer-group', style: 'solid'}) },
    { label: 'Landings', to: '/panel/landings', icon: icon({name: 'window-restore', style: 'solid'}) },
    { label: 'Seo metadatas', to: '/panel/seo-metadatas', icon: icon({name: 'search', style: 'solid'}) },
  ];
  

  return (
    <div>
      <AppNavbar items={items}/>
      <div className="container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-4">            
            <Routes>
              <Route path="/modules" element={<ModuleProvider><ModulePage/></ModuleProvider>} />
              <Route path="/module-models" element={<ModuleModelProvider><ModuleModelPage/></ModuleModelProvider>} />
              <Route path="/layouts" element={<LayoutProvider><LayoutPage/></LayoutProvider>} />
              <Route path="/landings" element={<LandingProvider><LandingPage/></LandingProvider>} />
              <Route path="/seo-metadatas" element={<SeoMetadataProvider><SeoMetadataPage/></SeoMetadataProvider>} />              
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
