import { useContext } from "react";
import { ModuleModelContext } from "../contexts/ModuleModelContext";

function usePreparePublish() {
  const {setElementIdToPublish, setShowPublishFormModal} = useContext(ModuleModelContext);
  return (id: any) => {
    setElementIdToPublish(id);        
    setShowPublishFormModal(true)
  }
}

export default usePreparePublish;