import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ModuleModelContext } from "../contexts/ModuleModelContext";
import update from "@beeldit/core/services/update";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(ModuleModelContext);
  const errorHandler = useErrorHandler();

  return (onSuccess: any) => {
    update("module_models", element.id, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
