import { useContext, useEffect} from 'react';
import CrudModuleFactoryTable from '@beeldit/core/components/CrudModuleFactoryTable';
import { FinalTableConfig } from '@beeldit/core/models/FinalTableConfig.interface';
import { LandingContext } from '../contexts/LandingContext';

interface Props {
    tableConfig: FinalTableConfig,
    tableData: any,
    onOrderByChange: any
}

function LandingTable(props: Props) {

    const { tableData, tableConfig, onOrderByChange } = props;
    const { orderBy } = useContext(LandingContext)

    useEffect(() => {
        tableConfig.index();
    }, []);    

    return (
        tableData ? (<CrudModuleFactoryTable config={tableConfig} data={tableData} orderBy={orderBy} onOrderByChange={onOrderByChange}></CrudModuleFactoryTable>) : (<div>Loading...</div>)
    )
}

export default LandingTable;
