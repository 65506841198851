import { useContext } from "react";
import { LandingContext } from "../contexts/LandingContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(LandingContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;