import { useContext } from "react";
import { LayoutContext } from "../contexts/LayoutContext";

function usePrepareRemove() {
  const {setElementIdToDelete, setShowDeleteConfirmationModal} = useContext(LayoutContext);
  return (id: any) => {
    setElementIdToDelete(id);        
    setShowDeleteConfirmationModal(true)
  }
}

export default usePrepareRemove;