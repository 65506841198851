import { useContext } from "react";
import { ModuleModelContext } from "../contexts/ModuleModelContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(ModuleModelContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;