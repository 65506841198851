import { useContext } from "react";
import { ModuleContext } from "../contexts/ModuleContext";

function usePrepareRemove() {
  const {setElementIdToDelete, setShowDeleteConfirmationModal} = useContext(ModuleContext);
  return (id: any) => {
    setElementIdToDelete(id);        
    setShowDeleteConfirmationModal(true)
  }
}

export default usePrepareRemove;