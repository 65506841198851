import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LandingContext } from "../contexts/LandingContext";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import "../scss/Template1.scss"; 

function PublicLandingPage() {
  const { element, setElement } = useContext(LandingContext);
  const errorHandler = useErrorHandler();
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    get("landings/preview", parseInt(id), "?relations=seoMetadata,media")
      .then((response: any) => {
        setElement(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, null);
      });
  }, [id]);

  if (!element) {
    return <div>Loading...</div>; 
  }

  const backgroundMedia = element.media.find((media: any) => media.collection_name === 'background');
  const logoMedia = element.media.find((media: any) => media.collection_name === 'logo');

  const backgroundImageStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundMedia?.original_url})`,
    backgroundPosition: "center", 
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  const contentContainerStyle: React.CSSProperties = {
    width: "50%",
    textAlign: "center",    
  };

  return (
    <div style={backgroundImageStyle}>
      <style dangerouslySetInnerHTML={{ __html: element.custom_css }} />
      <div style={contentContainerStyle}>
        {logoMedia && <img src={logoMedia.original_url} alt="Logo" />}
        <p className="text-box content-container" dangerouslySetInnerHTML={{ __html: element.content }} />
        <a href={element.button_url} className="landing-button">
          {element.button_text}
        </a>
      </div>
    </div>
  );
}

export default PublicLandingPage;
