import { t } from "i18next";
import { MouseEventHandler, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";

interface Context {
  showModal: boolean;
  setShowModal: (showModal: boolean) => MouseEventHandler;
}
interface ModalConfig {
  title: string;
  context: any;
  onSubmit: any;
  customShowModal?: boolean;
  customSetShowModal?: any;
}

interface Props {
  modalConfig: ModalConfig;
  children: React.ReactNode;  
}

function CrudModuleFactoryModal(props: Props) {
  const { title, context, onSubmit, customShowModal, customSetShowModal } = props.modalConfig;
  const { children } = props;
  const { showModal, setShowModal } = useContext<Context>(context);
  const handleClose = () => {
    if (customSetShowModal != undefined) {
      customSetShowModal(false);
      return;
    }
    setShowModal(false); // We pass the function to close the modal on success
  };
  const onSubmitFunction = () => {
    onSubmit();
  };
  const showModalValue = customShowModal != undefined ? customShowModal : showModal;

  return (
    <>
      <Modal show={showModalValue} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title as="div">{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={onSubmitFunction}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CrudModuleFactoryModal;
