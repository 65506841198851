import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { LandingContext } from "../contexts/LandingContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import LayoutSelector from "@main/modules/layouts/components/LayoutSelector";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";
import BeelditSwitchInput from "@beeldit/core/components/BeelditSwitchInput";
import BeelditWYSIWYGInput from "@beeldit/core/components/BeelditWYSIWYGInput";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
  };
}

function LandingForm(props: Prop) {
  const { formRef, onSubmit } = props.formConfig;
  const { element, setElement, backendFormErrors } = useContext(LandingContext);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("Nombre"),
    slug: t("Slug"),
    content_enabled: t("Contenido de texto activado"),
    content: t("Contenido de texto"),
    button_enabled: t("Botón activado"),
    button_text: t("Texto del botón"),
    button_url: t("Url del botón"),
    layout_id: t("Layout"),
    files: t("Imagen de fondo"),
    logo: t("Logo"),
    custom_css: t("CSS"),
  };

  let schema: any = {
    type: "object",
    properties: {
      name: { type: "string", title: translations.name },
      slug: { type: "string", title: translations.slug },
      content_enabled: { type: "boolean", title: translations.content_enabled },
      logo: {
        type: "array",
        title: translations.logo,
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        },
      },
      content: {
        type: "string",
        title: translations.content,
        show: element && element.content_enabled,
      },
      button_enabled: { type: "boolean", title: translations.button_enabled },
      button_text: {
        type: "string",
        title: translations.button_text,
        show: element && element.button_enabled,
      },
      button_url: {
        type: "string",
        title: translations.button_url,
        show: element && element.button_enabled,
      },
      files: {
        type: "array",
        title: translations.files,
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        },
      },
      custom_css: {
        type: "string",
        title: translations.custom_css,
        format: "textarea",
      },
      layout_id: { type: "integer", title: translations.layout_id },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    layout_id: {
      "ui:widget": "layout-selector",
    },
    content_enabled: {
      "ui:widget": "switch",
    },
    content: {
      "ui:widget": "wysiwyg",
    },
    button_enabled: {
      "ui:widget": "switch",
    },
    files: {
      "ui:widget": "file-input",
    },
    logo: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{
      "layout-selector": LayoutSelector,
      "file-input": BeelditFileInput,
      "switch": BeelditSwitchInput,
      "wysiwyg": BeelditWYSIWYGInput,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default LandingForm;
