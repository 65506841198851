import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ModuleContext } from "../contexts/ModuleContext";
import update from "@beeldit/core/services/update";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(ModuleContext);
  const errorHandler = useErrorHandler();

  return (onSuccess: any) => {
    update("modules", element.id, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
