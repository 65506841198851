import { useContext } from "react";
import { SeoMetadataContext } from "../contexts/SeoMetadataContext";

function usePrepareRemove() {
  const {setElementIdToDelete, setShowDeleteConfirmationModal} = useContext(SeoMetadataContext);
  return (id: any) => {
    setElementIdToDelete(id);        
    setShowDeleteConfirmationModal(true)
  }
}

export default usePrepareRemove;