import { useContext } from "react";
import { LayoutContext } from "../contexts/LayoutContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(LayoutContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;