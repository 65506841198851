import React from 'react';
import '../scss/BeelditSwitchInput.scss';  // Asumiendo que tienes un archivo CSS correspondiente

interface BeelditSwitchInputProps {
  id: string;
  required: boolean;
  readonly: boolean;
  disabled: boolean;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onBlur: (id: string, value: boolean) => void;
}

const BeelditSwitchInput: React.FC<BeelditSwitchInputProps> = ({ id, required, readonly, disabled, label, value, onChange, onBlur }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked);
  const handleBlur = () => onBlur(id, value);

  return (
    <div className="switch-container">
      <label className="switch">
        <input 
          type="checkbox" 
          id={id}
          required={required}
          readOnly={readonly}
          disabled={disabled}
          checked={typeof value === 'undefined' ? false : value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default BeelditSwitchInput;
