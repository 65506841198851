import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ModuleContext } from "../contexts/ModuleContext";
import get from "@beeldit/core/services/get";

function useGet() {
  const { setElement, setShowModal } = useContext(ModuleContext);
  const errorHandler = useErrorHandler();

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    get("modules", id)
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setElement(data);
        setShowModal(true);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useGet;
