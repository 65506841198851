import { useContext, useEffect } from "react";
import CrudModuleFactoryTable from "@beeldit/core/components/CrudModuleFactoryTable";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { ModuleContext } from "../contexts/ModuleContext";

interface Props {
  tableConfig: FinalTableConfig;
  tableData: any;
  onOrderByChange: any;
}

function ModuleTable(props: Props) {
  const { tableData, tableConfig, onOrderByChange } = props;
  const { orderBy } = useContext(ModuleContext);

  useEffect(() => {
    tableConfig.index();
  }, []);

  return tableData ? (
    <CrudModuleFactoryTable
      config={tableConfig}
      data={tableData}
      orderBy={orderBy}
      onOrderByChange={onOrderByChange}
    ></CrudModuleFactoryTable>
  ) : (
    <div>Loading...</div>
  );
}

export default ModuleTable;
