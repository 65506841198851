import { createRef, useEffect, useContext } from 'react';
import SeoMetadataForm from '../components/SeoMetadataForm';
import SeoMetadataTable from '../components/SeoMetadataTable';
import getConfig from '../configs/tableConfig';
import JSONSchemaForm from '@rjsf/core';

import getFiltersConfig from '../configs/filterConfig';
import { Card } from 'react-bootstrap';
import getFilterUIConfig from '../configs/filterUIConfig';
import moment from 'moment';
import { SeoMetadataContext } from '../contexts/SeoMetadataContext';
import useIndex from '../services/useIndex';
import useGet from '../services/useGet';
import useInitialize from '../services/useInitialize';
import useStore from '../services/useStore';
import useUpdate from '../services/useUpdate';
import usePrepareRemove from '../services/usePrepareRemove';
import useRemove from '../services/useRemove';
import CrudModuleFactoryModal from '@beeldit/core/components/CrudModuleFactoryModal';
import useFilters from '@beeldit/core/helpers/useFilters';
import AddQueryParamsToURL from '@beeldit/core/helpers/AddQueryParamsToURL';
import CrudModuleFactoryTableFilters from '@beeldit/core/components/CrudModuleFactoryTableFilters';
import CrudModuleFactoryConfirmationModal from '@beeldit/core/components/CrudModuleFactoryConfirmationModal';
import CrudModuleFactoryTablePagination from '@beeldit/core/components/CrudModuleFactoryTablePagination';
import CrudModuleFactoryTableHeaderActions from '@beeldit/core/components/CrudModuleFactoryTableHeaderActions';
import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import { t } from 'i18next';

function SeoMetadataPage() {

    const { user } = useContext(AuthContext);    

    const addQueryParamsToURL: any = AddQueryParamsToURL();

    /** Crud functions */
    const index = useIndex();
    const indexFunction = () => index();

    const get = useGet();
    const getFunction = (id: any) => get(id);

    const initialize = useInitialize();
    const initializeFunction = () => initialize();

    /**
     * Form store success function definition
     */
     const onSuccessStore = () => {
        handleClose() // We pass the function to close the modal on success
        index(); // We pass the function to refresh the table on success
    }

    const store = useStore();
    const storeFunction = () => store(onSuccessStore);

    /**
     * Form update success function definition
     */
    const onSuccessUpdate = () => {
        handleClose() // We pass the function to close the modal on success
        index(); // We pass the function to refresh the table on success
    }

    const update = useUpdate();
    const updateFunction = () => update(onSuccessUpdate);

    const prepareRemove = usePrepareRemove();
    const prepareRemoveFunction = (id: any) => prepareRemove(id);

    const remove = useRemove();
    const onSuccessRemove = () => {
        indexFunction();
    }
    const removeFunction = () => remove(onSuccessRemove);    

    const copy = (element: any) => {
        element.id = null;
        element.date = moment().format('YYYY-MM-DD');
        element.minutes = 0;
        return element;
    }

    const copyFunction = (id: any) => get(id, copy);  


    
    /** Filter loading */
    const {filtersLoaded} = useContext(SeoMetadataContext);   
    const {tableFilters, setTableFilters} = useContext(SeoMetadataContext);   
    useFilters({customer_id: null, status_id: null, report_id: null}, SeoMetadataContext);    

    /**Order by config */
    const {orderBy, setOrderBy} = useContext(SeoMetadataContext);

    const onOrderByChange = (orderBy: string) => {        
        setOrderBy(orderBy);
    }

    /**
     * Pagination config
     */
    const {paginationConfig, setPaginationConfig} = useContext(SeoMetadataContext);    

    const onPaginationChange = (page: number) => {
        setPaginationConfig({...paginationConfig, page: page});        
    }

    /** Index table funcion definition */
    const {tableData} = useContext(SeoMetadataContext);


    useEffect(() => {
        if(filtersLoaded) {
            index();
        }
    }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);
    


    /** Creation and edition modal function and state definition */
    const {showModal, setShowModal} = useContext(SeoMetadataContext);
    const handleClose = () => setShowModal(false); // We pass the function to close the modal on success


    /** Creation and edition modal element definition */
    const {element, setElement} = useContext(SeoMetadataContext);    
    
    /**
     * Form Reference definition
     */
    const formRef = createRef<JSONSchemaForm>();    
    
    /**
     * Creation and edition modal configuration definition
     */
    const modalConfig = {
        title: element && element.id ? 'Edit SeoMetadata' : 'Create SeoMetadata ',
        context: SeoMetadataContext,
        onSubmit: () => formRef.current?.submit() //We pass the function for the submit of the modal
    }
    
    /**
     * Delete Confirmation modal configuration definition
     */
     const deleteConfirmationModalConfig = {
        context: SeoMetadataContext,
        onSubmit: () => removeFunction() //We pass the function for the submit of the modal
    }

    /**
     * Form configuration definition
     */
    const formConfig = {
        formRef: formRef, // We pass the ref to the form
        onSubmit: (form: any) => {            
            if(form.formData.id) { // If the element has an id, we update it
                updateFunction();
            } else { // else we create it
                storeFunction();
            }            
        },
        element: element // We pass the element to the form in order to render it in the modal form
    }
    
    /**
     * Table configuration definition
     */
    const tableConfig = getConfig({
        initializeFunction, // We pass the function to initialize a new element (used in the create button)
        indexFunction, // We pass the function to index the table 
        getFunction, // We pass the function to get an element (used in the edit button)
        prepareRemoveFunction,
        copyFunction
    });

    /** Table Filters configuration */
    const filterUIConfig = getFilterUIConfig(user);
    const filterConfig = {
        schema: getFiltersConfig(user),
        uiSchema: filterUIConfig.uiScheme,
        widgets: filterUIConfig.widgets,
        element: tableFilters,
        onChange: (form: any) => {   
            setTableFilters(form.formData);            
            addQueryParamsToURL(form.formData);
        }   
    }
        
    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between ps-3 text-bg-primary">
                <span>{t('seometadata')}</span>
                <div>
                    <CrudModuleFactoryTableHeaderActions actions={tableConfig.actions}></CrudModuleFactoryTableHeaderActions>
                </div>
            </Card.Header>

            <Card.Body>
                {filtersLoaded ? (
                    <>
                        <CrudModuleFactoryTableFilters formConfig={filterConfig} />
                        <SeoMetadataTable tableData={tableData} tableConfig={tableConfig} onOrderByChange={onOrderByChange} />
                        <CrudModuleFactoryTablePagination paginationConfig={paginationConfig} totalElements={tableData.totalElements} onPaginationChange={onPaginationChange}></CrudModuleFactoryTablePagination>
                    </>
                ) : null}
                <CrudModuleFactoryModal modalConfig={modalConfig}>
                    <SeoMetadataForm formConfig={formConfig}/>
                </CrudModuleFactoryModal>
                <CrudModuleFactoryConfirmationModal modalConfig={deleteConfirmationModalConfig}>
                    ¿Desear eliminar el elemento?
                </CrudModuleFactoryConfirmationModal>
            </Card.Body>            
        </Card>
    );
}

export default SeoMetadataPage;
