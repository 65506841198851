import { useContext } from "react";
import api from "@beeldit/core/helpers/api";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ModuleContext } from "../contexts/ModuleContext";
import remove from "@beeldit/core/services/remove";

function useRemove() {
  const { elementIdToDelete, setShowDeleteConfirmationModal } =
    useContext(ModuleContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    remove("modules", elementIdToDelete)
      .then((response: any) => {
        setShowDeleteConfirmationModal(false);
        onSuccess();
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useRemove;
