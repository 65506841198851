import { useContext } from "react";
import { SeoMetadataContext } from "../contexts/SeoMetadataContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(SeoMetadataContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;