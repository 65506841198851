import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import remove from "@beeldit/core/services/remove";
import { useContext } from "react";
import { LayoutContext } from "../contexts/LayoutContext";

function useRemove() {
  const {elementIdToDelete, setShowDeleteConfirmationModal} = useContext(LayoutContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    remove('layouts', elementIdToDelete)
      .then((response: any) => {
        setShowDeleteConfirmationModal(false);
        onSuccess();
      }).catch((error: any) => {
        errorHandler(error)
    })
  }
}

export default useRemove;