import api from "@beeldit/core/helpers/api";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useContext } from "react";
import { ModuleModelContext } from "../contexts/ModuleModelContext";

function usePublish() {
  const { elementIdToPublish, setElementIdToPublish, setBackendFormErrors } =
    useContext(ModuleModelContext);
  const errorHandler = useErrorHandler();
  return (data: any, onSuccess: any) => {
    api
      .post(`/module_models/${elementIdToPublish}/publish`, data)
      .then((response: any) => {
        setElementIdToPublish(false);
        onSuccess();
      })
      .catch((error: any) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default usePublish;
