import { useContext } from "react";
import { ModuleContext } from "../contexts/ModuleContext";

function useInitialize() {
  const {setElement, setShowModal} = useContext(ModuleContext);
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;