import useErrorHandler from '@beeldit/core/errors/useErrorHandler';
import get from '@beeldit/core/services/get';
import { useContext } from 'react';
import { LandingContext } from '../contexts/LandingContext';

function useGet() {    const {setElement, setShowModal} = useContext(LandingContext);
    const errorHandler = useErrorHandler();
    
    return (id: any, onSuccess: any = (data: any) => {return data}) => {
      get('landings', id)
        .then((response: any) => {
          let data = response.data;
          data = onSuccess(response.data);
          setElement(data);
          setShowModal(true);
        })
        .catch((error: any) => {
          errorHandler(error)
        });
      
      
    }
  }

  
  export default useGet;