import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { SeoMetadataContext } from "../contexts/SeoMetadataContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditWYSIWYGInput from "@beeldit/core/components/BeelditWYSIWYGInput";
import LandingSelector from "@main/modules/landings/components/LandingSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
  };
}

function SeoMetadataForm(props: Prop) {
  const { formRef, onSubmit } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext(SeoMetadataContext);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("Name"),
    landing: t("Landing"),
    title: t("Title"),
    description: t("Description"),
    keywords: t("Keywords"),
    robots: t("Robots"),
    canonical_url: t("Canonical URL"),
    social_tags: t("Social Tags"),
    hreflang: t("Hreflang"),
    og_title: t("OG Title"),
    og_description: t("OG Description"),
    og_image: t("OG Image"),
    og_url: t("OG URL"),
    twitter_card: t("Twitter Card"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      // task: { type: 'string', title: translations.task},
      metadatable_type: {
        type: "string",
        title: "Metadatatable",
        default: "App\\Models\\Landing",
      },
      metadatable_id: { type: "integer", title: translations.landing },
      title: { type: "string", title: translations.title },
      description: { type: "string", title: translations.description, format: "textarea",},
      keywords: { type: "string", title: translations.keywords, format: "textarea",},
      robots: { type: "string", title: translations.robots },
      canonical_url: { type: "string", title: translations.canonical_url },
      hreflang: { type: "string", title: translations.hreflang },
      social_tags: {
        type: "object",
        title: translations.social_tags,
        properties: {
          "og_title": { type: "string", title: translations.og_title },
          "og_description": {
            type: "string",
            title: translations.og_description,
            format: "textarea",
          },
          "og_image": { type: "string", title: translations.og_image },
          "og_url": { type: "string", title: translations.og_url },
          "twitter_card": { type: "string", title: translations.twitter_card },
        }
      },      
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    metadatable_type: {
      "ui:widget": "hidden",
    },
    metadatable_id: {
      "ui:widget": "landing-selector",
    },    
  };
  const customWidgets = {
    ...widgets,
    ...{
      "landing-selector": LandingSelector,
      wysiwyg: BeelditWYSIWYGInput,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default SeoMetadataForm;
