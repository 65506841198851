import { useContext } from "react";
import { ModuleModelContext } from "../contexts/ModuleModelContext";

function usePrepareRemove() {
  const {setElementIdToDelete, setShowDeleteConfirmationModal} = useContext(ModuleModelContext);
  return (id: any) => {
    setElementIdToDelete(id);        
    setShowDeleteConfirmationModal(true)
  }
}

export default usePrepareRemove;